<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

export default {
  data() {
    return {
      beamer: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
   created(){
    const getStringVal = localStorage.getItem('isDarkTheme')
    const isDarkTheme = getStringVal === 'true' ? true : false;
    document.body.className = isDarkTheme ? 'dark' : '';
  },
  async mounted() {
    await this.$intercom.boot();
    setTimeout(() => (this.beamer = window.Beamer), 1000);
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (val.id) {
  
          Sentry.setUser({id: val.id, email: val.email});
    
          this.$intercom.boot({
            user_id: val.id,
            name: val.name,
            email: val.email,
          });
        }
      },
    },
    "$intercom.ready": function () {
      if (this.$intercom.ready) {
        this.$intercom.hide();
      }
    },
    "$i18n.locale": function () {
      if (this.beamer) {
        var beamer_config = {
          product_id: "reQereXj55815", //DO NOT CHANGE: This is your product code on Beamer
          button_position: "bottom-right",
          selector: "#beamer-news",
          language: localStorage.getItem("lang"),
        };
        this.beamer.update({
          ...beamer_config,
          language: localStorage.getItem("lang") === "ru" ? "ru" : "en",
        });
      }
    },
  },
};
</script>
