import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";
import router from "./router";
import DefaultLayout from "@/layouts/DefaultLayout";
import AuthLayout from "@/layouts/AuthLayout";
import PanelLayout from "@/layouts/PanelLayout";
import VueFlags from "@growthbunker/vueflags";
import * as Sentry from "@sentry/vue";
import Multiselect from "vue-multiselect";
import CustomMultiselect from "@/components/CustomMultiselect.vue";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
import { helper } from "./helpers/copyCode";
import moment from "moment";
import SvgSprite from "vue-svg-sprite";
import Vuelidate from "vuelidate";
import VueIntercom from "vue-intercom";
import { formatNumberWithSpaces, roundNumber } from "./filters/formatNumbers";
import MobileDetection from "./helpers/mobileDetection";
import Loader from "./components/Loader.vue";
import HeightTransition from "./components/HeightTransition";
import LottieAnimation from "lottie-web-vue";

import HighchartsVue from "highcharts-vue";
import VueGtm from "@gtm-support/vue2-gtm";

import alertModal from "./helpers/alert";

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

Vue.use(SvgSprite, {
  url: require("./assets/sprite/symbol.svg"),
  class: "icon-symbol",
});

Vue.filter("formatNumberWithSpaces", formatNumberWithSpaces);
Vue.filter("roundNumber", roundNumber);

Vue.prototype.$moment = moment;
Vue.prototype.$helper = helper;

Vue.use(VueGtm, {
  id: "GTM-TB7ZSRM2",
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: true,
});
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);
Vue.use(VModal);
Vue.use(VueIntercom, { appId: "eyauf2ue" });
Vue.use(VueFlags, {
  iconPath: "/images/flags/",
});
Vue.use(LottieAnimation);

Vue.use(FloatingVue);
Vue.use(HighchartsVue);

Vue.use(MobileDetection);

Sentry.init({
  Vue,
  release: process.env.GIT_TAG ?? "unknown",
  dsn: "https://c2dc113dd772411d9291478244072b88@errors.house/11",
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],

  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});
Vue.component("multiselect", Multiselect);
Vue.component("custom-multiselect", CustomMultiselect);
Vue.component("default-layout", DefaultLayout);
Vue.component("panel-layout", PanelLayout);
Vue.component("auth-layout", AuthLayout);
Vue.component("loader", Loader);
Vue.component("transition-height", HeightTransition);

Vue.prototype.$formatLabel = function (el) {
  return this.$t(el);
};
Vue.prototype.$alert = alertModal;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
